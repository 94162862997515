export const PLATFORMS = [`ios`, `android`, `windows`, `macos`, `web`];
export function isPlatform(x) {
    return PLATFORMS.includes(x);
}
export const ORIENTATIONS = [`portrait`, `landscape`];
export function isOrientation(x) {
    return ORIENTATIONS.includes(x);
}
export var ConfigType;
(function (ConfigType) {
    ConfigType["fontSize"] = "fontSize";
    ConfigType["lineHeight"] = "lineHeight";
})(ConfigType || (ConfigType = {}));
export var Unit;
(function (Unit) {
    Unit["rem"] = "rem";
    Unit["em"] = "em";
    Unit["px"] = "px";
    Unit["percent"] = "%";
    Unit["vw"] = "vw";
    Unit["vh"] = "vh";
    Unit["none"] = "<no-css-unit>";
})(Unit || (Unit = {}));
